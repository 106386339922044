import React, { useEffect } from 'react';

import PageLayout from 'components/layouts/PageLayout';
import InvestmentOffers from 'components/content/InvestmentOffers';
import InfoPanel from 'components/content/InfoPanel';

const Home = ({
  statisticsItems = [],
  investmentOffersItems = [],
  promotionsList,
  ...restProps
}) => {
  useEffect(() => {
    restProps.hideLoader();
  }, [restProps.url.asPath, JSON.stringify(restProps.url.query)]);

  return (
    <PageLayout showMap {...restProps}>
      <InvestmentOffers
        key='investmentOffers'
        items={investmentOffersItems}
        isPromotionsActive={promotionsList.length}
      />
      <InfoPanel key='infoPanel' data={statisticsItems} />
    </PageLayout>
  );
};

export default Home;
