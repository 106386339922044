import styled, { css } from 'styled-components';

const PageLoader = styled.div`
  position: fixed;
  z-index: 102;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-Color: rgba(0, 0, 0, 0.3);
  ${(props) => css`
    display: ${props.$overlay ? 'flex' : 'none'};
  `};
  justify-content: center;
  align-items: center;
  ${({ $isContainerLoader }) => $isContainerLoader && css`
    position: absolute;
    z-index: 1;
  `};
`;

export {
  PageLoader,
};
