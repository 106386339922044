import { injectIntl } from 'react-intl';
import Link from 'components/ui/Link';

import _get from 'lodash/get';
import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import {
  OffersListitemWrapper,
  Image,
  ItemHeader,
  ItemDescription,
  ItemInfo,
  ItemInfoTitle,
  ItemInfoValue,
} from './OffersListItem.styles';
import messages from './OffersListItem.messages';
import { VisibilityContainer } from '../../../../../../../utils/styles';
import config from '../../../../../../../config';

const OffersListItem = ({ item = {}, intl, isPromotion = false }) => {
  const openExternalWindow = () => {
    if (item.id) {
      const windowUrl = _get(config.investmentExternalWindow, item.id, false);

      if (windowUrl && item.fieldInvestmentUpcoming) {
        window.open(
          windowUrl,
          'Start_external',
          `width=${window.screen.availWidth}, height=${window.screen.availHeight}, fullscreen=1`
        );
      }
    }
  };

  const openExternalTab = () => {
    if (item.id && item.fieldInvestmentExternalUrl) {
      window.open(item.fieldInvestmentExternalUrl, '_blank');
    }
  };

  const handleClick = () => {
    openExternalWindow();
    openExternalTab();
    trackYouleadVirtualPageView(`${item.id}/opis`);
  };

  const getRoute = () => {
    if (item.fieldInvestmentExternalUrl) {
      return '/';
    }

    if (item.goTo.route === 'wyszukiwarka') {
      const stringifiedSearchParams = new URLSearchParams(
        item.goTo.params
      ).toString();
      return `wyszukiwarka?${stringifiedSearchParams}`;
    }
    return isPromotion ? 'promotionsList' : item.goTo.params?.id;
  };

  return (
    <OffersListitemWrapper>
      <Link
        href={getRoute()}
        params={isPromotion ? { page: 1 } : item.goTo.params}
        onClick={handleClick}
      >
        <Image src={item.image} alt='slide' />
        <ItemHeader>{item.title}</ItemHeader>
        <ItemDescription>
          {item.description &&
            item.description.address &&
            intl.formatMessage(messages.checkAvailableLocals) !==
              item.description.address && (
              <ItemInfo>
                <ItemInfoTitle>
                  {intl.formatMessage(messages.address)}
                </ItemInfoTitle>
                <ItemInfoValue>&nbsp;{item.description.address}</ItemInfoValue>
              </ItemInfo>
            )}
          {item.description &&
            item.description.address &&
            intl.formatMessage(messages.checkAvailableLocals) ===
              item.description.address && (
              <ItemInfo>
                <ItemInfoTitle>{item.description.address}</ItemInfoTitle>
              </ItemInfo>
            )}
          <VisibilityContainer $sm $xs $md $lg>
            {item.description && item.description.realizationDate ? (
              <ItemInfo>
                <ItemInfoTitle>
                  {intl.formatMessage(messages.realizationDate)}
                </ItemInfoTitle>
                <ItemInfoValue>
                  &nbsp;{item.description.realizationDate}
                </ItemInfoValue>
              </ItemInfo>
            ) : null}
          </VisibilityContainer>
        </ItemDescription>
      </Link>
    </OffersListitemWrapper>
  );
};

export default injectIntl(OffersListItem);
