import React, { useEffect, useState } from 'react';
import { PageLoader } from './Loader.styles';

const Loader = ({
  overlay = false,
  isContainerLoader = false,
  minTime = 0,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (minTime > 0) {
      setIsVisible(true);
      timeoutId = setTimeout(() => {
        setIsVisible(overlay);
      }, minTime);
    } else {
      setIsVisible(overlay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [minTime, overlay]);

  return (
    <>
      <PageLoader $overlay={isVisible} $isContainerLoader={isContainerLoader}>
        <div className='spinner' />
      </PageLoader>

      <style jsx global>
        {`
          .page-loader {
            position: fixed;
            z-index: 121;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            display: ${overlay ? 'flex' : 'none'};
            justify-content: center;
            align-items: center;
          }

          .spinner {
            width: 8vmax;
            height: 8vmax;
            border-right: 4px solid #ffffff;
            border-radius: 100%;
            animation: spinRight 800ms linear infinite;
          }

          .spinner:before,
          .spinner:after {
            content: '';
            width: 6vmax;
            height: 6vmax;
            display: block;
            position: absolute;
            top: calc(50% - 3vmax);
            left: calc(50% - 3vmax);
            border-left: 3px solid #ffffff;
            border-radius: 100%;
            animation: spinLeft 800ms linear infinite;
          }

          .spinner:after {
            width: 4vmax;
            height: 4vmax;
            top: calc(50% - 2vmax);
            left: calc(50% - 2vmax);
            border: 0;
            border-right: 2px solid #ffffff;
            animation: none;
          }

          @keyframes spinLeft {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(720deg);
            }
          }

          @keyframes spinRight {
            from {
              transform: rotate(360deg);
            }
            to {
              transform: rotate(0deg);
            }
          }
        `}
      </style>
    </>
  );
};

export default Loader;
