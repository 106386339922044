import { Component } from 'react';

const MountComponentPage = (Page) =>
  class MountComponent extends Component {
    constructor(props) {
      super(props);

      this.state = { mounted: false };
    }

    componentDidMount() {
      this.setState({ mounted: true });
    }

    render() {
      return <Page {...this.props} mountedComponent={this.state.mounted} />;
    }
  };

export default MountComponentPage;
