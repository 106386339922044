import { connect } from 'react-redux';
import HomePage from 'components/pages/Home';
import Loader from 'components/Loader';
import { hideLoader, showLoader } from 'containers/sharedStore';
import selector from './selector';

const Home = (props) => (
  <>
    <Loader overlay={props.isLoaderShown} />
    <HomePage {...props} />
  </>
);

const mapDispatchToProps = {
  hideLoader,
  showLoader,
};

export default connect(selector, mapDispatchToProps)(Home);
