import withSizes from 'utils/withSizes';
import MountComponent from 'components/MountComponent';
import InfoBox from './components/InfoBox';
import { Wrapper, Container } from './InfoPanel.styles';

const InfoPanel = ({ data = [] }) => (
  <Wrapper>
    <Container>
      {data.map((el, key) => (
        <InfoBox key={key} {...el} />
      ))}
    </Container>
  </Wrapper>
);

export default withSizes()(MountComponent(InfoPanel));
