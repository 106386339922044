import { createSelector } from 'reselect';
import SharedSelector from 'containers/sharedSelector';
import values from 'lodash/values';
import config from 'config';
import isPromotionActive from 'utils/isPromotionActive';

const PromotionsListSelector = (state) => state.PromotionsListPage;
const DateSelector = (state, props) => props.date;

export const PromotionsListDateSelector = createSelector(
  PromotionsListSelector,
  DateSelector,
  (promotions) => ({
    items:
        promotions.items.filter(
          (el) => isPromotionActive({ startDate: el.startDate, endDate: el.endDate }),
        ),
  }),
);

const pageSelector = (state, props) => props.url.query.page || 1;
const investmentSelector = (state, props) => props.url.query.investment;

const sortInvestments = (a, b) => a.name.localeCompare(b.name);

const PromotionsInvestmentsSelector = createSelector(
  PromotionsListDateSelector,
  (promotions) => {
    const investments = {};

    promotions.items.forEach((el) => {
      if (el.investment) {
        investments[el.investment.id] = {
          id: el.investment.id,
          name: el.investment.title,
        };
      }
    });

    let list = values(investments);

    list = list.sort(sortInvestments);

    return list;
  },
);

const PromotionsSelector = createSelector(
  PromotionsListDateSelector,
  pageSelector,
  investmentSelector,
  (promotionsList, page, investmentId) => {
    const fromElement = (page - 1) * config.pagination.promotionsList;
    const toElement = fromElement + config.pagination.promotionsList;

    const promotionsItems = promotionsList.items
      .filter((el) => investmentId === undefined ||
        (el.investment && el.investment.id === parseInt(investmentId)));

    const items = promotionsItems
      .slice(fromElement, toElement)
      .map((el) => ({
        ...el,
        text: el.summary,
      }));

    const allItemsCount = Math.ceil(promotionsItems.length / config.pagination.promotionsList);

    return {
      items,
      allItemsCount,
    };
  },
);

export default createSelector(
  SharedSelector,
  PromotionsSelector,
  PromotionsInvestmentsSelector,
  investmentSelector,
  (shared, promotionsListData, investmentList, investmentSelected) => ({
    ...shared,
    data: {
      ...promotionsListData,
      investmentList,
      investmentSelected,
    },
  }),
);
